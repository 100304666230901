<template>
  <v-dialog
    v-model="displayDialog"
    width="800"
    :overlay-opacity="0.8"
    @keydown.esc.stop.prevent="displayDialog = false"
    @click:outside="displayDialog = false"
  >
    <!-- The card ensures that the background is correct -->
    <v-card aut-history-dialog>
      <DialogTitle @close="displayDialog = false" title="History" />
      <v-card-text class="timeline">
        <v-timeline dense>
          <v-slide-x-reverse-transition group hide-on-leave>
            <!-- :color="item.color" -->
            <v-timeline-item
              v-for="item in historyItems"
              :key="item._id"
              small
              fill-dot
            >
              <v-row class="pt-1">
                <v-col cols="12" lg="3">
                  {{ formattedDate(item._created_date) }}
                </v-col>
                <v-col>
                  <v-alert :type="item.type">
                    <div>{{ item._created_by }}</div>
                    <div v-html="formatChange(item)"></div>
                  </v-alert>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-slide-x-reverse-transition>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { format, parseISO } from "date-fns";
import { mapState } from "vuex";
import { makeServerCall } from "@/util";
import { pageMixin } from "@/components/mixin.js";
const debug = require("debug")("atman.components.history_dialog");
debug(`history_dialog`);
export default {
  name: "HistoryDialog",
  mixins: [pageMixin, dialogMixin],
  data() {
    return {
      historyItems: [],
    };
  },
  computed: {
    ...mapState(["historyParams"]),
    displayDialog: {
      get() {
        return this.$store.state.displayHistory;
      },
      set(value) {
        return this.$store.commit("toggleHistoryDialog", value);
      },
    },
  },
  watch: {
    historyParams() {
      this.getHistory();
    },
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    formatChange(content) {
      let result = "";
      const changeLog = content.formatted_log;
      result += changeLog.join("<br/>");
      return result;
    },
    formattedDate(dateValue) {
      return format(parseISO(dateValue), "yyyy-MM-dd HH:mm:ss z");
    },
    async getHistory() {
      if (!this?.historyParams?.context) {
        return;
      }
      const response = await makeServerCall({
        url: `${this.historyParams.context}/_history?format=YES`,
        type: "get",
      });
      this.historyItems = response?.data || [];
    },
  },
};
</script>
